'use client';
import { mobileSize, useMediaQuery } from 'hooks/use-media-query';
import { usePathname } from 'next/navigation';
import React from 'react';
import { cn } from 'utils/cn.util';
import HydrationWrapper from './hydration-wrapper';

const FooterWrapper = ({ children }: React.PropsWithChildren) => {
  const pathname = usePathname();
  const isMobile = useMediaQuery(mobileSize);
  const isSearch = pathname.includes('search');
  return (
    <HydrationWrapper>
      <div
        className={cn('block', {
          hidden: isSearch && isMobile,
        })}
      >
        {children}
      </div>
    </HydrationWrapper>
  );
};

export default FooterWrapper;
