'use client';

import { useParams, usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { HamburgerIcon, HearthIcon } from 'components/icon';
import Image from 'next/image';
import { Link } from 'components/link';
import { MobileMenuType } from 'types/cms/mobile-menu';
import { useMediaQuery } from 'hooks/use-media-query';

import ShippingMethod from 'components//header/shipping-method';
import SearchBar from 'components/search-bar';
import MobileMegamenu from './mega-menu';
import HeaderLoyalty from '../loyalty';
import { myWishlistHref } from 'utils/route-urls';
import { HeaderStyle } from 'types/header';
import { useTranslation } from 'app/i18n/client';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function MobileMenuHeader({
  logo,
  data,
  socialLinks,
  settings,
  algoliaConfig,
  taxIcons,
  language,
  region,
}: MobileMenuType) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'footer'
  );

  const [openMegaMenu, setOpenMegaMenu] = useState(false);
  const matches = useMediaQuery('(min-width: 1024px)');
  const [matchesStatus, setMatchesStatus] = useState(false);
  const params = useParams<{
    collection: string;
    handle: string;
  }>();
  const pathname = usePathname();

  const style: HeaderStyle = {};
  if (settings.asset_type === 'PLAIN' && settings?.background_color) {
    style.backgroundColor = settings.background_color;
  }
  if (settings.asset_type === 'IMAGE' && settings.web_background_image) {
    style.backgroundImage = `url('${settings.web_background_image}')`;
  }

  function handleMegaMenu() {
    setOpenMegaMenu(true);
    document.querySelector('body')?.classList.add('overflow-hidden');
  }

  useEffect(() => {
    setMatchesStatus(matches);
  }, [matches]);
  if (matchesStatus) return null;

  if (pathname?.includes('/search') && !params?.collection) return;

  return (
    <>
      <div className='relative z-[60] bg-primary-blue' style={style}>
        {settings.asset_type === 'VIDEO' && (
          <video
            src={settings?.mobile_background_video}
            autoPlay
            loop
            muted
            className='absolute inset-0 z-[-1] h-full w-full object-cover'
          />
        )}
        <div className='flex items-center justify-between px-4 py-3'>
          <div className='flex-1'>
            <ShippingMethod
              textColor={settings.shipping_text_color}
              language={language}
              region={region}
            />
          </div>
          <HeaderLoyalty textColor={settings.label_text_color} />
        </div>
        <div className='h-[1px] w-full bg-white opacity-10'></div>
        <div className='flex items-center gap-4 p-3'>
          <HamburgerIcon
            onClick={() => handleMegaMenu()}
            style={{ fill: settings.hamburger_text_color }}
          />
          <Link href='/' className='h-8 w-[34px]'>
            <Image
              src={logo}
              alt={t('nahdi_global') as unknown as string}
              width={34}
              height={32}
            />
          </Link>
          {/* icon width + logo width + paddings sum = 86px */}
          <div
            className='flex items-center gap-1'
            style={{ width: 'calc(100% - 86px)' }}
          >
            <SearchBar
              language={language}
              region={region}
              algoliaConfig={algoliaConfig}
              searchAsYouType={
                settings?.search?.instant_search === 'on' ? true : false
              }
              placeholder={settings?.search_placeholder || ''}
              multiPlaceholder={settings?.multiple_search_placeholder}
              textColor={settings?.search_text_color}
            />

            <Link href={myWishlistHref}>
              <div className='m-0 flex h-11 w-10 items-center justify-center rounded-md border border-gray-400 bg-white lg:border-2'>
                <HearthIcon className='has-color fill-current text-primary-blue' />
              </div>
            </Link>
          </div>
        </div>
      </div>

      <MobileMegamenu
        data={data}
        openMegaMenu={openMegaMenu}
        setOpenMegaMenu={setOpenMegaMenu}
        socialLinks={socialLinks}
        taxIcons={taxIcons ?? []}
        language={language}
        region={region}
      />
    </>
  );
}
