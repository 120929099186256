'use client';

import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { MobileSubChildMenuType } from 'types/cms/mobile-menu';

import { PlusIcon, MinusIcon, ArrowLeftIcon } from 'components/icon';
import { Link } from 'components/link';
import { Underline } from 'components/buttons/underline';
import { useTranslation } from 'app/i18n/client';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import ContentfulLink from 'components/contentful-link';

export default function MobileSubChildMenu({
  data,
  openChildMenu,
  setOpenChildMenu,
  language,
  region,
}: MobileSubChildMenuType) {
  const [activeAccordion, setActiveAccordion] = useState(-1);
  const pathname = usePathname();
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'header'
  );

  useEffect(() => {
    setOpenChildMenu(false);
  }, [pathname, setOpenChildMenu]);

  if (!data) return null;

  function handleAccordion(index: number) {
    if (activeAccordion === index) {
      setActiveAccordion(-1);
      return;
    } else {
      setActiveAccordion(index);
    }
  }

  function handleBack() {
    setOpenChildMenu(false);
    handleAccordion(-1);
  }

  const AccordionIconHtml = (index: number) => {
    if (activeAccordion === index) {
      return (
        <MinusIcon
          className='absolute top-[20px] h-4 w-4 ltr:right-4 rtl:left-4'
          onClick={() => handleAccordion(index)}
        />
      );
    } else {
      return (
        <PlusIcon
          className='absolute top-[20px] h-4 w-4 ltr:right-4 rtl:left-4'
          onClick={() => handleAccordion(index)}
        />
      );
    }
  };

  return (
    <div
      className={`fixed bottom-0 top-0 z-[80] h-dvh w-full overflow-y-auto bg-white transition-all duration-500 ease-in-out ${
        openChildMenu
          ? 'ltr:left-0 rtl:right-0'
          : 'ltr:-left-[1300px] rtl:-right-[1300px]'
      }`}
    >
      <div className='px-4 pb-6 pt-8'>
        <div className='flex items-center space-x-2 rtl:space-x-reverse'>
          <ArrowLeftIcon
            onClick={() => handleBack()}
            className='rtl:rotate-180'
          />
          <span className='text-custom-lg font-semibold'>{data.label}</span>
        </div>
      </div>

      {data.megamenu_item_list?.length > 0 && (
        <ul className='divide-y divide-light-gray-300 border-t border-light-gray-300'>
          {data.megamenu_item_list?.map((item: any, index: number) => (
            <li
              key={index + '-' + item?.label}
              className={`relative px-4 py-5 ${
                activeAccordion === index ? 'bg-light-gray-200' : ''
              }`}
              {...(item?.megamenu_item_list && {
                onClick: () => handleAccordion(index),
              })}
            >
              <Link
                href={item?.megamenu_item_list ? ' ' : item.link || ''}
                {...(item?.megamenu_item_list && {
                  onClick: (e: any) => e.preventDefault(),
                })}
                className={`flex items-center space-x-3 text-custom-base rtl:space-x-reverse ${
                  activeAccordion === index
                    ? 'font-semibold text-blue'
                    : 'text-black'
                }`}
              >
                {item.icon && (
                  <Image
                    src={item.icon}
                    alt={item.label_alternate}
                    width={0}
                    height={0}
                    sizes='100vw'
                    className='h-[32px] w-[32px]'
                  />
                )}

                <span>{item.label}</span>
              </Link>

              {item.megamenu_item_list && <>{AccordionIconHtml(index)}</>}

              <ul
                className={`bg-['rgba(244, 248, 250, 1)'] z-[1] w-full overflow-hidden text-black transition-all duration-500 ease-in-out ${
                  activeAccordion === index
                    ? 'visible mt-1 h-auto opacity-100'
                    : 'invisible mt-0 h-0 opacity-0'
                }`}
              >
                {item.megamenu_item_list?.map(
                  (child_item: any, child_index: number) => {
                    if (
                      child_item.label_text_decoration?.includes('UNDERLINED')
                    ) {
                      return (
                        <li key={child_index} className='py-1 text-sm'>
                          <Underline href={child_item.link || ''}>
                            {child_item.label}
                          </Underline>
                        </li>
                      );
                    }
                    return (
                      <li
                        key={child_index}
                        className='space-x-2 px-0 py-1 pt-4 text-custom-base rtl:space-x-reverse'
                      >
                        <ContentfulLink
                          item={child_item}
                          onClick={(e: any) => e.stopPropagation()}
                          language={language}
                          region={region}
                        >
                          {child_item.label}
                          {child_item.badge_text_color &&
                            child_item.badge_background_color && (
                              <span
                                style={{
                                  backgroundColor:
                                    child_item.badge_background_color,
                                  color: child_item.badge_text_color,
                                }}
                                className='ms-1 rounded-xl bg-[#FF0000] px-2 py-1 text-xs font-bold text-white'
                              >
                                {child_item.badge_text || t('new')}
                              </span>
                            )}
                        </ContentfulLink>
                      </li>
                    );
                  }
                )}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
