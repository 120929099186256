'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { CloseIcon, ChevronRightIcon } from 'components/icon';
import Image from 'next/image';
import { Link } from 'components/link';
import { MobileMegaMenuType } from 'types/cms/mobile-menu';
import { handleSignout } from 'utils/sign-out-handler';
import { useTranslation } from 'app/i18n/client';
import SelectLanguage from 'components/header/select-language';
import ShippingCountry from 'components/header/shipping-country';
import LoginButton from 'components/header/user-button';
import MobileSubChildMenu from './sub-child-menu';

import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { useAppSelector } from 'redux/hooks';
import HydrationWrapper from 'components/hydration-wrapper';
import ContentfulLink from 'components/contentful-link';

export default function MobileMegamenu({
  data,
  openMegaMenu,
  setOpenMegaMenu,
  socialLinks,
  taxIcons,
  language,
  region,
}: MobileMegaMenuType) {
  const [activeChildMenu, setActiveChildMenu] = useState(null);
  const [openChildMenu, setOpenChildMenu] = useState(false);
  const pathname = usePathname();
  const { t, ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'header'
  );

  const miniProfile = useAppSelector(
    (state) => state.customerReducer.miniProfile
  );

  useEffect(() => {
    setOpenMegaMenu(false);
    document.querySelector('body')?.classList.remove('overflow-hidden');
  }, [pathname, setOpenMegaMenu]);

  function handleClose() {
    setOpenMegaMenu(false);
    document.querySelector('body')?.classList.remove('overflow-hidden');
  }

  const handleChildMenu = (item: any) => {
    setActiveChildMenu(item);
    setOpenChildMenu(true);
  };

  const ItemHtml = (item: any, index: number) => {
    return (
      <ContentfulLink
        item={item}
        className={'flex items-center space-x-2 rtl:space-x-reverse'}
        language={language}
        region={region}
      >
        {(() => {
          switch (index) {
            case 0:
              return <span className='text-custom-base'>{item.label}</span>;
            case 1:
              return (
                <span className='font-semibold text-red hover:text-red'>
                  {item?.label}
                </span>
              );
            default:
              return <span className='text-custom-base'>{item.label}</span>;
          }
        })()}
      </ContentfulLink>
    );
  };

  const ExtraClassName = (item: any) => {
    if (item?.className) {
      return item?.className;
    }
    return '';
  };

  const ParentItemClassName = (item: any) => {
    if (item?.megamenu_item_list) {
      return 'flex items-center justify-between space-x-2 rtl:space-x-reverse';
    }
    return '';
  };

  return (
    <>
      <div
        className={`fixed bottom-0 top-0 z-[80] h-dvh w-full overflow-y-auto bg-white pb-10 transition-all duration-500 ease-in-out ${
          openMegaMenu
            ? 'ltr:left-0 rtl:right-0'
            : 'ltr:-left-[1200px] rtl:-right-[1200px]'
        }`}
      >
        <div className='px-4 py-3 pt-8'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center space-x-1 text-lg rtl:space-x-reverse'>
              <HydrationWrapper>
                {!miniProfile && (
                  <>
                    <span className='text-custom-lg font-semibold'>
                      {t('user.hi')} {t('user.hi_guest')}
                    </span>
                  </>
                )}

                {miniProfile && (
                  <>
                    <span className='text-custom-lg font-semibold'>Hi</span>
                    <span className='text-custom-lg font-semibold'>
                      {miniProfile?.firstname}
                    </span>
                  </>
                )}
              </HydrationWrapper>
            </div>
            <CloseIcon onClick={() => handleClose()} />
          </div>

          <div className='mt-6'>
            <LoginButton language={language} region={region} />
          </div>
        </div>

        <ul className='divide-y'>
          <li className='px-4 py-5'>
            <Link href='/' className='text-custom-base'>
              {t('home')}
            </Link>
          </li>

          {data?.map((item: any, index: number) => (
            <li
              key={index + '-' + item?.label}
              className={`${
                ExtraClassName(item) + ParentItemClassName(item)
              } px-4 py-5 text-custom-base`}
              {...(item?.megamenu_item_list && {
                onClick: () => handleChildMenu(item),
              })}
            >
              {ItemHtml(item, index)}
              {item?.megamenu_item_list && (
                <ChevronRightIcon className='h-6 w-6 translate-x-2 text-gray rtl:rotate-180' />
              )}
            </li>
          ))}

          <li className='flex items-center justify-between p-4 text-custom-base'>
            <span>{t('language')}</span>
            <SelectLanguage language={language} region={region} />
          </li>

          <li className='flex items-center justify-between p-4 text-custom-base'>
            <span>{t('shipping_country')}</span>
            <ShippingCountry language={language} region={region} />
          </li>

          <HydrationWrapper>
            {miniProfile && (
              <li className='flex items-center justify-between p-4 text-custom-base'>
                <form
                  onSubmit={(e) => {
                    handleSignout(true, e);
                  }}
                >
                  <button
                    type='submit'
                    className='text-sm font-semibold text-blue underline'
                  >
                    {t('user.logout')}
                  </button>
                </form>
              </li>
            )}
          </HydrationWrapper>
        </ul>

        <div className='mt-5 space-x-2 px-4 rtl:space-x-reverse'>
          {/* it can be download links via live site */}
          <Link
            href='/'
            className='float-left block'
            style={{ width: 'calc(50% - 5px)' }}
          >
            <Image
              src='/assets/images/footer/app-store.svg'
              alt={ts('app_store')}
              width={0}
              height={0}
              // className='h-[50px] w-[167px]'
              className='w-full'
              // style={{ width: 'calc(50% - 3px)' }}
            />
          </Link>
          <Link
            href='/'
            className='float-left block'
            style={{ width: 'calc(50% - 5px)' }}
          >
            <Image
              src='/assets/images/footer/google-play.svg'
              alt={ts('google_play')}
              width={0}
              height={0}
              className='w-full'
              // className='h-[50px] w-[167px]'
              // style={{ width: 'calc(50% - 3px)' }}
            />
          </Link>
        </div>

        <div className='clear-both flex items-end justify-between px-4 pt-5'>
          <div>
            <span className='text-custom-xs font-semibold text-gray-dark'>
              {t('stay_touch')}
            </span>
            <div className='mt-3 flex space-x-2 rtl:space-x-reverse'>
              {socialLinks?.footerSocialItems?.map(
                (footerSocialLinkItem: any, index: number) => (
                  <div
                    className='h-9 w-9 rounded-full border border-[#BDBDBD]'
                    key={index}
                  >
                    <a
                      href={footerSocialLinkItem.link || ''}
                      target='_blank'
                      className='cursor-pointer'
                    >
                      <Image
                        src={footerSocialLinkItem.icon}
                        width={0}
                        height={0}
                        alt={
                          footerSocialLinkItem.title ?? 'footerSocialLinkItem'
                        }
                        className='h-full w-full'
                        sizes='100vw'
                      />
                    </a>
                  </div>
                )
              )}
            </div>
          </div>
          {taxIcons && (
            <div className='mt-4 flex items-center gap-2'>
              {taxIcons.map((icon, index) => (
                <div key={index}>
                  <ContentfulLink
                    item={icon}
                    className='h-auto w-[47px]'
                    language={language}
                    region={region}
                  >
                    <Image
                      src={icon.image}
                      alt={'tax icon'}
                      width={0}
                      height={0}
                      sizes='100vw'
                      className='h-full w-full'
                    />
                  </ContentfulLink>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <MobileSubChildMenu
        data={activeChildMenu || { megamenu_item_list: [], label: null }}
        openChildMenu={openChildMenu}
        setOpenChildMenu={setOpenChildMenu}
        language={language}
        region={region}
      />
    </>
  );
}
